.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    opacity: 0; /* Start with 0 opacity */
    animation: fadeIn 1s ease-in-out forwards; /* Apply the fadeIn animation */
  }
  
  .content {
    text-align: center;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1; /* Fade in to full opacity */
    }
  }
  
  h1 {
  
    margin-bottom: 1rem;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle text shadow */
  }
  

  
  /* Style a button for a potential "Return to Home" action */
  
  