.menu-sidebar {
  transition: all 0.3s;
  /* Dark sidebar background */
  color: #ecf0f1;
}
.menu-sidebar .logo {
  background: #f5f5f5;
  height: 67px;
  padding: 0 35px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e5e5e5;
  position: relative;
  z-index: 3;
}
.menu-sidebar .logo img {
  max-width: 100%;
}
.navbar-sidebar .navbar__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.section {
  max-height: 0;

  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
}

.section.open {
  max-height: 682px; /* Adjust based on content */
  
}

.section.open[data-section="admin"] {
 
  overflow-y: auto; /* Enable vertical scrolling for entire admin section */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

.section.open[data-section="admin"]::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar for WebKit browsers */
}

.section.open[data-section="admin"]::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.section.open[data-section="admin"]::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px;
}

.section.open[data-section="admin"]::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.menu-item {
  transition: background-color 0.3s, padding-left 0.3s;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #ecf0f1;
}

.menu-item:hover {
  background-color: #505050; /* Darker background on hover */
  padding-left: 20px;
  color: #fff;
}

.sidebar_active {
  background-color: #3498db; /* Active item background color */
  color: white;
  font-weight: bold;
}

.sidebar_deactive {
  color: #a9a9a9; /* Inactive item text color */
}

.section-header {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ecf0f1;
  background-color: #9da0a3;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

li.menu-item.has-dropdown.open {
  display: flex
;
  flex-direction: column;
  background-color: #F5F5F5;
  align-items: stretch;
}
.section-header:hover {
  background-color: #3498db;
}

.menu-item ul {
  list-style: none;
  margin-top: 5px;
  max-height: 200px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
  padding-left: 0; /* Remove left padding */
}

.menu-item ul li {
  padding: 8px 0;
  transition: all 0.3s ease;
}

.menu-item ul li a {
  color: black;
  text-decoration: none;
  display: block;
  font-size: 12px;
}

.menu-item.has-dropdown {
  position: relative;
}

.menu-item.has-dropdown > a:after {
  content: '▼';
  font-size: 8px;
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.menu-item.has-dropdown.open > a:after {
  transform: rotate(180deg);
}

.menu-item ul::-webkit-scrollbar,
.menu-item.has-dropdown ul::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar for WebKit browsers */
  height: 8px; /* Horizontal scrollbar height */
}

.menu-item ul::-webkit-scrollbar-track,
.menu-item.has-dropdown ul::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.menu-item ul::-webkit-scrollbar-thumb,
.menu-item.has-dropdown ul::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px;
}

.menu-item ul::-webkit-scrollbar-thumb:hover,
.menu-item.has-dropdown ul::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.menu-item.has-dropdown ul li {
  padding: 8px 10px; /* Add padding for better touch target and spacing */
  color: black;
}

.menu-item.has-dropdown ul li:hover {
  background-color: #f0f0f0; /* Add a subtle hover effect */
}
