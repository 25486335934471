.fond {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(62, 58, 58, 0.5);
  z-index: 9999; /* Adjust this value as needed */
}
.loader {
  width: 52.8px;
  height: 52.8px;
  color: #6366F1;
  position: relative;
  background: radial-gradient(15.2px,currentColor 94%,#0000);
}

.loader:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(10.08px at bottom right,#0000 94%,currentColor) top    left,
          radial-gradient(10.08px at bottom left ,#0000 94%,currentColor) top    right,
          radial-gradient(10.08px at top    right,#0000 94%,currentColor) bottom left,
          radial-gradient(10.08px at top    left ,#0000 94%,currentColor) bottom right;
  background-size: 22.4px 22.4px;
  background-repeat: no-repeat;
  animation: loader 0.8s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes loader {
  33% {
    inset: -11.2px;
    transform: rotate(0deg);
  }

  66% {
    inset: -11.2px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}









/* .contener_general
{
  -webkit-animation:animball_two 1s infinite;
  -moz-animation:animball_two 1s infinite;
  -ms-animation:animball_two 1s infinite;
  animation:animball_two 1s infinite;
  width:44px; height:44px;
}
.contener_mixte
{
  width:44px; height:44px; position:absolute;
}
.ballcolor
{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ball_1, .ball_2, .ball_3, .ball_4
{
  position: absolute;
  z-index: 1;
  -webkit-animation:animball_one 1s infinite ease;
  -moz-animation:animball_one 1s infinite ease;
  -ms-animation:animball_one 1s infinite ease;
  animation:animball_one 1s infinite ease;
}
.ball_1
{
  background-color:#cb2025;
  top:0; left:0;
}
.ball_2
{
  background-color:#f8b334;
  top:0; left:24px;
}
.ball_3
{
  background-color:#6366F1;
  top:24px; left:0;
}
.ball_4
{
  background-color:rgb(178, 0, 255);
  top:24px; left:24px;
}

@-webkit-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@-moz-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@-ms-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-webkit-keyframes animball_two
{
  0%{-webkit-transform:rotate(0deg) scale(1);}
  50%{-webkit-transform:rotate(360deg) scale(1.3);}
  100%{-webkit-transform:rotate(720deg) scale(1);}
}
@-moz-keyframes animball_two
{
  0%{-moz-transform:rotate(0deg) scale(1);}
  50%{-moz-transform:rotate(360deg) scale(1.3);}
  100%{-moz-transform:rotate(720deg) scale(1);}
}
@-ms-keyframes animball_two
{
  0%{-ms-transform:rotate(0deg) scale(1);}
  50%{-ms-transform:rotate(360deg) scale(1.3);}
  100%{-ms-transform:rotate(720deg) scale(1);}
}
@keyframes animball_two
{
  0%{transform:rotate(0deg) scale(1);}
  50%{transform:rotate(360deg) scale(1.3);}
  100%{transform:rotate(720deg) scale(1);}
} */