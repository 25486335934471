.manage-departments-container {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color:#ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  /* font-size: 24px; */
  margin-bottom: 20px;
  color: #B200FF;
}

.department-form,
.designation-form {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

input {
  flex: 1;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 16px;
}

.add-button {
  background-color: #0074cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.add-button:hover {
  background-color: #005aa7;
}

.department-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between; /* Distribute cards evenly */
}

.department-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px); /* Initially 3 cards per row */
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px; /* Add some spacing between cards */
}

.department-card:hover {
  transform: translateY(-5px);
}

.department-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.department-header span {
  flex: 1;
  font-size: 16px;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-container button {
  background-color: #0074cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.icon-container button:hover {
  background-color: #005aa7;
}

.designation-container {
  margin-top: 10px;
  font-size: 16px;
}

h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.add-designation-button {
  background-color: #0074cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.add-designation-button:hover {
  background-color: #005aa7;
}

.save-icon {
  color: green;
  cursor: pointer;
}

.cancel-icon {
  color: rgb(255, 0, 0);
  cursor: pointer;
}

.edit-icon {
  color: rgb(167, 164, 164);
  cursor: pointer;
}

.delete-icon {
  color: rgb(255, 0, 0);
  cursor: pointer;
}

.edit-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.designation-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .department-card {
    width: calc(50% - 20px); /* 2 cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .department-card {
    width: 100%; /* 1 card per row on even smaller screens */
  }
}
