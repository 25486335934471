.popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background:  */
  background-color: rgba(247, 246, 248, 1);

  border: 1px solid #ccc;
  padding: 20px;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-content p {
  margin: 5px 0;
}

.popup-content button {
  margin-top: 10px;
}
.popup-content form {
  display: flex;
  flex-direction: column;
}
.popup-content form label {
  font-weight: bold;
}

/* Add your custom CSS styles */
.modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #3498db;
  color: white;
}

.modal-body {
  padding: 20px;
}

.modal-title {
  font-size: 24px;
}
.swal2-popup {
  width: 20rem !important;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
