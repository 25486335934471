.icon {
    opacity: 0.9;
    transition: font-size 0.5s;
    font-size: 2.5em;
    transition: font-size 0.5s ease, opacity 0.3s ease; 
    color: rgb(255, 255, 255);
  }
  .wigdet-one-content{
    height: 8rem !important;
  }
  li.w-100.d-flex.justify-content-center {
    color: black;
}
  .snowman {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .snowman.exploded {
    animation: snowman-blast 0.7s ease-out forwards;
  }
  
  @keyframes snowman-blast {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(2.5) rotate(15deg);
      opacity: 0.7;
    }
    100% {
      transform: scale(4) rotate(-15deg);
      opacity: 0;
    }
  }
  
  .card:hover .icon {
    font-size: 3em;
    opacity: 1; 
  }
  .card {
    transition: none;
    
  }
  
  .snowflake {
  position: fixed;
  top: -10px;
  z-index: 9999;
  color: white;
  font-size: 1.5em;
  user-select: none;
  pointer-events: none;
  animation: fall linear infinite;
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}

/* Creating multiple snowflakes with random positions and delays */
.snowflake:nth-child(1) {
  left: 10%;
  animation-duration: 8s;
  animation-delay: 0s;
}
.snowflake:nth-child(2) {
  left: 20%;
  animation-duration: 10s;
  animation-delay: 2s;
}
.snowflake:nth-child(3) {
  left: 30%;
  animation-duration: 6s;
  animation-delay: 4s;
}
.snowflake:nth-child(4) {
  left: 40%;
  animation-duration: 7s;
  animation-delay: 1s;
}
.snowflake:nth-child(5) {
  left: 50%;
  animation-duration: 9s;
  animation-delay: 3s;
}
.snowflake:nth-child(6) {
  left: 60%;
  animation-duration: 6s;
  animation-delay: 5s;
}
.snowflake:nth-child(7) {
  left: 70%;
  animation-duration: 11s;
  animation-delay: 2s;
}
.snowflake:nth-child(8) {
  left: 80%;
  animation-duration: 7s;
  animation-delay: 4s;
}
.snowflake:nth-child(9) {
  left: 90%;
  animation-duration: 10s;
  animation-delay: 6s;
}
.snowflake:nth-child(10) {
  left: 95%;
  animation-duration: 12s;
  animation-delay: 1s;
}

.new-year-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.664);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

.new-year-content {
  text-align: center;
  color: #e6ef3b;
  position: relative;
  width: 100%;
  font-family: 'Sixtyfour', cursive;
}

.year-transition {
  position: relative;
  height: 180px;
  margin-bottom: 30px;
  perspective: 1000px;
}

.year {
  font-size: 120px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  transition: all 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.year.fade-out {
  transform: translateX(-100%) scale(0.8);
  opacity: 0;
}

.year.fade-in {
  transform: translateX(0) scale(1);
  opacity: 1;
}

.new-year-2025 {
  display: flex;
  justify-content: center;
  gap: 10px;
  transform: translateX(100%);
  opacity: 0;
}

.new-year-2025.fade-in {
  transform: translateX(0);
  opacity: 1;
}

.digit {
  display: inline-block;
  color: #FFD700;
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% { text-shadow: 0 0 20px rgba(255, 215, 0, 0.5); }
  50% { text-shadow: 0 0 40px rgba(255, 215, 0, 0.8), 0 0 60px rgba(255, 215, 0, 0.4); }
}

.new-year-message {
  opacity: 0;
  transform: scale(0.5);
  transition: all 1s ease;
}

.celebration-text {
  font-size: 48px;
  font-weight: bold;
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400%;
  animation: gradient 15s ease infinite;
  margin-bottom: 15px;
}

.sparkles {
  font-size: 24px;
  color: #FFD700;
  animation: sparkleText 1.5s infinite;
}

.new-year-message.show {
  opacity: 1;
  transform: scale(1);
}

@keyframes sparkleText {
  0% { opacity: 0.5; transform: scale(0.95); }
  50% { opacity: 1; transform: scale(1.05); }
  100% { opacity: 0.5; transform: scale(0.95); }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.firecracker {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  animation: shoot 1s ease-out;
}

@keyframes shoot {
  0% {
    transform: translateY(100vh) scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
}

.spark {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: gold;
  animation: spark 0.5s linear;
}

@keyframes spark {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(var(--tx), var(--ty)) scale(0);
    opacity: 0;
  }
}

