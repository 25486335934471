.leave-container {
    padding: 20px;
    margin: 5rem;

    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.leave-table {
    width: 100%;
    border-collapse: collapse;
}

.leave-table th, .leave-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.leave-table th {
    background-color: #21117D;
    color: white;
    font-weight: bold;
}

.leave-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.leave-table tr:hover {
    background-color: #ddd;
}

.leave-table tr:nth-child(odd) {
    background-color: #ffffff;
}
