.chat-widget {
    position: fixed;
    bottom: 5rem;
    right: 20px;
    z-index: 1000;
}


.chat-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #1acc8d;
    border: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bot-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.chat-icon:hover .bot-icon {
    transform: scale(1.1);
}

.chat-icon:hover {
    background: #16b37e;
    transform: translateY(-2px);
}

.chat-tooltip {
    position: absolute;
    right: 60px;
    background: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    pointer-events: none;
}

.chat-icon:hover .chat-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateX(-5px);
}

.chat-board {
    position: fixed;
    bottom: 74px;
    right: 15px;
    width: 274px;
    height: 400px;
    border-radius: 12px;
    background: white;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    display: flex
;
    flex-direction: column;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.chat-header {
    padding: 12px 15px;
    background: #1acc8d;
    color: white;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.header-icon {
    font-size: 18px;
}

.chat-header h3 {
    margin: 0;
    font-size: 1rem;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    transition: background-color 0.2s;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.chat-messages {
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background: #f8f9fa;
}

.message {
    margin-bottom: 12px;
    display: flex;
}

.message-content {
    max-width: 85%;
    padding: 12px 15px;
    border-radius: 15px;
    position: relative;
    line-height: 1.6;
}

.message-content p {
    margin: 0;
    font-size: 15px;
}

.bot-message .question-list {
    color: #2d3436;
    font-size: 14px;
    margin-top: 10px;
}

.bot-message .question-number {
    color: #1acc8d;
    font-weight: bold;
    margin-right: 5px;
}

.bot-message .question-text {
    color: #2d3436;
}

.bot-message .welcome-text {
    color: #1acc8d;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.user-message {
    justify-content: flex-end;
}

.user-message .message-content {
    background: #1acc8d;
    color: white;
}

.bot-message .message-content {
    background: #ffffff;
    color: #212529;
    border: 1px solid #e9ecef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.bot-message {
    justify-content: flex-start;
}

.bot-message .answer-text {
    color: #2d3436;
    font-size: 14px;
    line-height: 1.6;
}

.timestamp {
    font-size: 0.7rem;
    color: rgba(108, 117, 125, 0.8);
    margin-top: 4px;
    display: block;
}

.user-message .timestamp {
    color: rgba(255, 255, 255, 0.8);
}

.chat-input {
    padding: 12px;
    background: white;
    border-top: 1px solid #dee2e6;
    border-radius: 0 0 12px 12px;
    display: flex;
    gap: 8px;
}

.chat-input input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    outline: none;
    font-size: 14px;
    transition: border-color 0.2s;
}

.chat-input input:focus {
    border-color: #1acc8d;
}

.chat-input button {
    width: 35px;
    height: 35px;
    background: #1acc8d;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.chat-input button:hover {
    background: #16b37e;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .chat-board {
        width: calc(100% - 100px);
        height: calc(79vh - 100px);
        bottom: 80px;
    }

    
    .chat-icon {
        width: 45px;
        height: 45px;
    }
    
    .bot-icon {
        font-size: 20px;
    }
}
