.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 18px;
}

.Register-wrap {
  max-width: 430px;
  min-width: 380px;
  text-align: center;
  border-radius: 10px;
}

.Register-content {
  max-width: 430px;
  min-width: 380px;
  text-align: center;
  border-radius: 10px;
}

.Register_button-container {
  display: flex;
  justify-content: space-between;
}

form {
  text-align: left;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #7a7777;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6e6969;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.container {
  background-color: #ffffff;
  width: 100%;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #6366f1 !important;
  border-color: #6366f1 !important;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon1 {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6e6969;
}

.container {
  background-color: #ffffff;
  /* margin-top: 20Px; */
  width: 100%;
}
/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  /* Adjust styles here for smaller screens */
  .container {
    /* Example adjustment */
    padding: 20px;
  }
}
h5 {
  text-align: left;
}
.enter-message {
  text-align: left;
}
