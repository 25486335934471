.salary-slip-container {
  padding: 20px;
  background-color: #f5f5f5;
  font-family: 'Helvetica', Arial, sans-serif;
  line-height: 1.6;
}

.salary-slip-buttons {
  margin-bottom: 20px;
  margin-top: 3rem;
}

.salary-slip-buttons button {
  margin-left: 10px;
  padding: 8px 15px;
  font-size: 0.85rem;
  font-weight: bold;
  border-radius: 5px;
}

.salary-slip-buttons .btn-success {
  background-color: #28a745;
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}
.salary-slip-buttons .btn-success:hover {
  background-color: #218838;
}

.salary-slip-buttons .btn-danger {
  background-color: #dc3545;
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}
.salary-slip-buttons .btn-danger:hover {
  background-color: #c82333;
}

.salary-slip {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #bbb6b6;
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.company-info h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
}

.company-info p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #777;
}

.company-logo img {
  height: 60px;
  width: auto;
}

.employee-pay-summary {
  margin-bottom: 25px;
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-left {
  flex: 1;
}

.summary-right {
  text-align: right;
}

.summary-header {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 12px;
  color: #555;
}

.pay-summary-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.employee-column {
  width: 30%;
  font-size: 0.9rem;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.summary-table td {
  padding: 6px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

.earnings-deductions {
  margin: 35px auto;
}

.table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #f8f9fa;
  text-align: left;
  padding: 10px;
  font-size: 0.95rem;
  border: 1px solid #ddd;
}

.table td {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

tfoot {
  font-weight: bold;
  background-color: #e9ecef;
}

.text-center {
  text-align: center;
}

.footer {
  text-align: center;
  color: #777;
  margin-top: 20px;
  padding-top: 15px;
}

.footer p {
  font-size: 0.85rem;
  font-style: italic;
}

.net-payable {
  padding-top: 15px;
  border: 2px dotted #bbb6b6;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.net-payable p {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.net-payable p.italic {
  font-size: 0.9rem;
  font-style: italic;
  color: #555;
}
