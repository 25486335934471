/* TimeTracker.css */

.time-tracker-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow: hidden;
}

.time-tracker-table th,
.time-tracker-table td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: left;
  position: relative;
}

.time-tracker-table th i.fa-filter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  cursor: pointer;
  color: rgb(135, 144, 153);
}

.time-tracker-table th:hover i.fa-filter {
  color: #000; /* Change the color when hovered for better visibility */
}

.time-tracker-table td {
  background-color: #fff;
}

.time-tracker-table tr:hover {
  background-color: #f9f9f9;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-info {
  font-size: 16px;
  color: #555;
  margin-right: 20px;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-buttons {
  display: flex;
  align-items: center;
}

.pagination-button {
  padding: 12px 20px;
  cursor: pointer;
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 8px;
  margin-right: 8px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.pagination-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination-button:hover {
  background-color: #4f52f2; /* Change the color on hover */
}

.pagination-select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-left: 10px;
  font-size: 14px;
}

.filter-popup {
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.filter-popup input {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.filter-popup button {
  background-color: #4caf50;
  color: white;
  padding: 5px 6px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}

.filter-popup button:hover {
  background-color: #45a049;
}

.filter-popup button:last-child {
  background-color: #f44336;
}

.filter-popup button:last-child:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .time-tracker-table {
    font-size: 10px;
  }

  .filter-popup {
    width: 90%!important;
    left: 50%!important;
    transform: translateX(-50%);
    top: 20%!important;
    max-height: 80vh;
    overflow-y: auto;
  }

  .pagination-button {
    padding: 5px 6px;
    font-size: 10px;
  }

  .pagination-select {
    padding: 10px;
    font-size: 12px;
  }
}
/* Add this section for a more modern and interactive design */
.filter-formdd {
  position: absolute;
  margin-right: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 1000;
  width: 300px;
  top: 50px;
  right: 0;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  
}

.no-data-message {
  margin-top: 20px;
  padding: 10px;
text-align: center;/* Light blue background color */
 /* Blue border */
  /* Dark blue text color */

}
.filter-formdd label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.filter-formdd input,
.filter-formdd select {
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.filter-formdd select {
  cursor: pointer;
}

.filter-formdd button {
  background-color: #6366F1;
  color: white;
  padding: 7px 7px;
  margin-right: 8px; 
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.filter-formdd button:hover {
  background-color: #45a049;
}

.filter-formdd button:last-child {
  background-color: #f44336;
}

.filter-formdd button:last-child:hover {
  background-color: #d32f2f;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .filter-formdd {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    max-height: 80vh;
    overflow-y: auto;
  }
}

.applied-filters {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter-label {
  font-weight: bold;
}

.filter-values {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.filter-item {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.filter-text {
  font-size: 12px; /* Adjust the font size as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
}

.btn-clear-filter {
  background-color: #fff;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

.clear-filters-btn {
  margin-top: 10px;
  color: #6366f1;
  text-decoration: underline;
  cursor: pointer;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -15px;
  
  z-index: 1001; /* Ensure that the close icon container is above the filter form */
}

.close-icon-container i {
  cursor: pointer;
  font-size: 18px;
  color: #d32f2f;
}
