.leave-info-container {
  display: flex;
  flex-wrap: wrap;
}

.leave-item {
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.leave-item:hover {
  transform: scale(1.05);
}

.leave-property {
  font-weight: bold;
  color: #333;
}

.leave-value {
  color: #555;
}
.leave-form {
  max-width: 400px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.leave-form input {
  width: 100%;
  /* padding: 8px; */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-container {
  max-width: 400px;
}

.form-container label {
  display: block;
}

.form-container input,
.form-container select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-container select {
  appearance: none;
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23404444" width="18px" height="18px"><path d="M12 16l-6-6h12z"/></svg>')
    no-repeat right 10px center;
}
.time-input {
  padding: 8px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}
.attendance-rule-info {
  margin-top: 10px;
  margin-left: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.attendance-rule-info p {
  margin-bottom: 10px;
}

.attendance-rule-info small {
  color: #dc3545;
}
