.header-desktop {
  background-color: rgb(76, 69, 105);
  background-image: linear-gradient(
    rgb(63, 105, 190),
    rgba(87, 86, 140, 0.474)
  );
}
.divider {
  border: 0;
  border-top: 1px solid #ccc;
}

.header-desktop {
  background-image: linear-gradient(
    to right,
    #ffffff,
    #c5bedf,
    #8e80be,
    #58459c,
    #180878
  );
}
.account-dropdown {
  position: absolute;
  min-width: 300px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  right: 0;
  top: 100%;
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.account-dropdown.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.account-dropdown .info {
  padding: 5px;
  border-bottom: 1px solid #f2f2f2;
}

.account-dropdown .info .image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  float: left;
}

.account-dropdown .info .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.account-dropdown .info .content {
  margin-left: 75px;
}

.account-dropdown .info .name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.account-dropdown .info .email {
  font-size: 14px;
  color: #999;
}

.account-dropdown a {
  display: block;
  padding: 15px 25px;
  font-size: 14px;
  transition: all 0.3s ease;
  text-decoration: none;
  color: darkslategrey;
}

.account-dropdown a:hover {
  background-color: #f5f5f5;
}

.image.rounded-circle {
  width: 45px;
  height: 45px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.image.rounded-circle:hover {
  transform: scale(1.05);
}

.image.rounded-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.account-dropdown__footer {
  text-align: center;
  background-color: #6361ff;
}

.account-dropdown__footer a {
  color: rgb(42, 49, 189) b8b;
  text-decoration: none;
}

.account-dropdown__footer div {
  padding: 5px;
}

.account-dropdown__footer div:hover {
  text-decoration: underline;
  background-color: #ec2b2b;
  color: aliceblue;
}

.nameChanger {
  font-size: 15px;
}

.swal-button-width {
  width: 100px !important;
}
