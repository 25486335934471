.AppliedDate,
.DateDuration {
  font-size: medium;
  font-weight: 600;
  color: #181616;
}
.contentLable {
  font-weight: 500;
  color: rgb(64, 63, 63);
}
.CurrStatus {
  font-weight: 700;
}
.Dialogbox {
  width: 100%;
  position: fixed;
  left: 30%;
  transform: translate(-50%, -100%);
}
ul {
  /* padding: 10px; */
  height: "50%";
  width: 100%;
}

img {
  float: left;
}
.chat-about {
  margin-left: 20px;
}
.chat {
  width: 40%;
  background: #f2f5f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;

  .chat-history {
    .message {
      color: white;
      padding: 10px 20px;
      line-height: 15px;
      font-size: 16px;
      border-radius: 7px;
      margin-bottom: 15px;
      width: 90%;
      position: relative;
    }
    .message.bg-primary {
      margin-left: 10px;
    }
    .message.bg-success {
      margin-right: 10px;
    }
  }
}
