.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 4px 30px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.header.home-section {
  background: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  box-shadow: none;
  border-bottom: none;
}

.header.sticked {
  padding: 12px 0;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px) saturate(200%);
  -webkit-backdrop-filter: blur(15px) saturate(200%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 
    0 4px 30px rgba(0, 0, 0, 0.15),
    inset 0 0 0 1px rgba(255, 255, 255, 0.15);
}

.header .logo {
  text-decoration: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}

.header .logo img {
  max-height: 65px;
  width: auto;
  transition: all 0.3s ease;
}

.header .logo img.home-logo {
  max-height: 75px;
}

.header.sticked .logo img {
  max-height: 55px;
}

.navmenu {
  padding: 0;
  margin: 0;
}

.navmenu ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  gap: 30px;
}

.navmenu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  transition: 0.3s;
  text-decoration: none;
  border-radius: 30px;
}

/* Home section specific styles */
.header.home-section:not(.sticked) .navmenu a {
  color: #fff;
}

@media (max-width: 991px) {
  .header.home-section:not(.sticked) .navmenu a {
    color: #000;
  }
  
  .header.home-section:not(.sticked) .navmenu a:hover {
    color: #4154f1;
  }
}

.header.sticked .navmenu a,
.header:not(.home-section) .navmenu a {
  color: rgba(0, 0, 0, 0.8);
}

/* Active and hover states */
.header:not(.home-section) .navmenu a:hover,
.header:not(.home-section) .navmenu .active,
.header.sticked .navmenu a:hover,
.header.sticked .navmenu .active {
  color: #4154f1;
}

.header.home-section:not(.sticked) .navmenu a:hover,
.header.home-section:not(.sticked) .navmenu .active {
  color: #4154f1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

/* Mobile Navigation */
@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 24px;
    cursor: pointer;
    line-height: 1;
    transition: 0.5s;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 9999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: 15px;
  }

  .error-icon {
    margin-right: 60px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
  }

  .mobile-nav-toggle i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    .header.home-section:not(.sticked) .mobile-nav-toggle {
        color: #ffffff;
        background: rgb(255 255 255 / 13%);
    }
}

@media (max-width: 991px) {
    .header.sticked .mobile-nav-toggle {
        background: rgb(0 0 0 / 20%);
        color: rgb(255, 255, 255);
    }
}
  .navmenu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    transition: 0.3s;
    z-index: 9997;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 80px 0 0 0;
    box-shadow: 
      -5px 0 30px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    overflow-y: auto;
  }

  .header.home-section .navmenu {
    background: rgba(0, 0, 0, 0.85);
  }

  .navmenu ul {
    display: block;
    padding: 0 15px;
    margin: 0;
    list-style: none;
  }

  .navmenu ul li {
    position: relative;
    padding: 0;
    margin: 8px 0;
    list-style: none;
  }

  .navmenu a {
    display: block;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border-radius: 10px;
    margin: 0;
    text-align: left;
    text-decoration: none;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .header.home-section .navmenu a {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
  }

  .header.home-section .navmenu a:hover,
  .header.home-section .navmenu .active {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
  }

  .navmenu a:hover,
  .navmenu .active {
    color: #4154f1;
    background: rgba(65, 84, 241, 0.1);
    transform: translateX(5px);
  }

  .navmenu.mobile-nav-active {
    right: 0;
    box-shadow: -5px 0 30px rgba(0, 0, 0, 0.15);
  }

  body.mobile-nav-active {
    overflow: hidden;
  }

  .header .logo img {
    max-height: 50px;
  }

  .header .logo img.home-logo {
    max-height: 60px;
  }

  .header.sticked .logo img {
    max-height: 45px;
  }
}

@media (min-width: 992px) {
  .mobile-nav-toggle {
    display: none;
  }
}

/* Container */
.container-fluid {
  padding: 0 50px;
  max-width: 1320px;
  margin: 0 auto;
}

/* Scroll to top and bottom buttons */
.scroll-buttons {
  position: fixed;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.scroll-buttons.show {
  opacity: 1;
  visibility: visible;
}

.scroll-buttons.top {
  top: 20px;
}

.scroll-buttons.bottom {
  bottom: 20px;
}

.scroll-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #4154f1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.scroll-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.scroll-button i {
  font-size: 20px;
  transition: all 0.3s ease;
}

.scroll-button:hover i {
  transform: scale(1.1);
}

/* Dark mode styles */
.home-section .scroll-button {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.home-section .scroll-button:hover {
  background: rgba(255, 255, 255, 0.25);
}

/* Mobile styles */
@media (max-width: 768px) {
  .scroll-buttons {
    right: 15px;
  }
  
  .scroll-buttons.top {
    top: 15px;
  }
  
  .scroll-buttons.bottom {
    bottom: 15px;
  }
  
  .scroll-button {
    width: 35px;
    height: 35px;
  }
  
  .scroll-button i {
    font-size: 18px;
  }
}
