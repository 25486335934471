/* TableData.css */
.reject-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .reject-popup-content {
    max-width: 400px;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
  }
  
  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  

.p-dialog .p-dialog-footer button {
  height: 30px!important;
}
