/* Modern Login Page Styles */
.login-page {
  min-height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
input#email {
  padding-left: 35px;
}
input#password {
  padding-left: 35px;
}
.main-content {
  padding-top: 5px !important;
}
.page-container {
  background: #ffffff;
 
}
.login-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  min-height: 600px;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

.login-left {
  flex: 1;
  background: linear-gradient(135deg, #f5f7ff 0%, #e9ecff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.login-image-container {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
}

.login-illustration {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.login-right {
  flex: 1;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-container {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.logo-container {
  margin-bottom: 1.5rem;
}

.logo-container img {
  height: 40px;
  width: auto;
}

.login-header h2 {
  font-size: 1.75rem;
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.login-header p {
  color: #718096;
  font-size: 0.95rem;
}

.login-form {
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-size: 0.9rem;
  font-weight: 500;
}

.input-container {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
}

.form-control {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  font-size: 0.95rem;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
  outline: none;
}

.password-toggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.password-toggle:hover {
  color: #6366f1;
}

.form-actions {
  margin-top: 2rem;
}

.btn-login {
  width: 100%;
  padding: 0.875rem;
  background: #6366f1;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-login:hover {
  background: #4f46e5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.signup-section {
  margin-top: 1.5rem;
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid #e2e8f0;
}

.signup-section p {
  color: #718096;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.btn-signup {
  display: inline-block;
  padding: 0.625rem 1.5rem;
  background-color: #ffffff;
  color: #6366f1;
  border: 2px solid #6366f1;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.btn-signup:hover {
  background-color: #6366f1;
  color: #ffffff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .login-container {
    max-width: 900px;
  }

  .login-right {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    min-height: auto;
    margin: 1rem;
  }

  .login-left {
    display: none; /* Hide the illustration container on mobile */
  }

  .login-right {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .login-page {
    padding: 0.5rem;
  }

  .login-container {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .login-left {
    padding: 1.5rem 1rem;
  }

  .login-image-container {
    padding: 0.5rem;
  }

  .login-right {
    padding: 1.5rem 1rem;
  }

  .login-header h2 {
    font-size: 1.5rem;
  }

  .form-control {
    padding: 0.625rem 1rem 0.625rem 2.25rem;
  }
}

/* Handle very small screens */
@media (max-width: 320px) {
  .login-page {
    padding: 0;
  }

  .login-container {
    border-radius: 0;
  }

  .login-right {
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}

/* Handle landscape mode */
@media (max-height: 600px) and (orientation: landscape) {
  .login-container {
    min-height: auto;
  }

  .login-left {
    padding: 1rem;
  }

  .login-image-container {
    max-width: 250px;
  }
}
