.reimbursement_info {
  font-size: 16px;
  font-weight: 600;
}
.Reimbursement_status {
  font-size: 16px;
  font-weight: 700;
}
.h5.pb-2.mt-2.mb-2.border-bottom.text-primary {
  margin-top: 3.5rem !important;
}