/* Grid layout for filters */
.filter-container {
  display: flex;
  gap: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
}

.p-multiselect-panel {
  z-index: 1 !important;
  width: 15%;
}
svg.p-icon.p-multiselect-trigger-icon.p-c {
  color: black !important;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #000000 !important;
  font-weight: bold;
}

button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
width: 20px;
height: 35px;
background-color: black;
}
.filter-group {
  display: flex;
  flex-direction: column;
  min-width: unset;
  margin-right: 0;
}

.filter-group label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #333;
}
.p-multiselect {
  display: inline-flex;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  min-height: 35px;
  height: auto;
  z-index: 1;
}
.p-multiselect-panel {
  position: absolute;
  z-index: 1000;
}

.p-multiselect .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 4px;
  overflow: hidden;
}

.p-multiselect-token {
  background: #e9ecef;
  color: #333;
  border-radius: 3px;
  padding: 2px 6px;
  margin: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
}

.p-multiselect-trigger {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form-select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f8f9fa;
  font-size: 0.9rem;
  transition: border-color 0.2s ease;
}

.form-select:hover {
  border-color: #ffffff;
}

.form-select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Button styling */
.btn-primary {
  height: 38px;
  padding: 0 16px;
  background-color: #2F467A;;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .filter-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.d-flex {
  display: flex !important;

}
.header-marquee {
background-color: #f8f9fa;
padding: 10px 0;
border-bottom: 1px solid #e9ecef;
}

.header-marquee marquee {
color: #0d6efd;
font-size: 16px;
font-weight: 500;
}

/* Header styles */
.clockify-header {
background-color: #1c8ffcba;
padding: 12px 24px;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #2e3544;
margin-bottom: 15px;
border-radius: 7px;
gap: 16px;
}
.p-calendar-w-btn-right .p-inputtext {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
background-color: #fffffffc;
color: #000000;
}

.totals-section {
display: flex;
gap: 24px;
align-items: center;
}

.total-item {
display: flex;
align-items: baseline;
gap: 8px;
}

.total-item .label {
color: #a4b0c5;
font-size: 14px;
}

.total-item .value {
color: #e4e7ed;
font-size: 16px;
font-family: monospace;
font-weight: 500;
}

.actions-section {
display: flex;
gap: 12px;
align-items: center;
}

.header-button1 {
background: transparent;
border: 1px solid #fcfdff96;
color: #e4e7ed;
padding: 6px 12px;
border-radius: 4px;
font-size: 14px;
cursor: pointer;
display: flex;
align-items: center;
gap: 6px;
}
button.btn.btn-outline-secondary.ms-2 {
  border-color: #ffffff98;
}
.d-flex.align-items-center {
  border-color: rgba(255, 255, 255, 0.645);
}
.header-button1:hover {
background: #899dc6;
}

.toggle-group {
display: flex;
align-items: center;
}

.toggle-label {
color: #e4e7ed;
font-size: 14px;
display: flex;
align-items: center;
gap: 8px;
cursor: pointer;
}

.toggle-input {
display: none;
}

.toggle-slider {
width: 36px;
height: 20px;
background: #2e3544;
border-radius: 10px;
position: relative;
transition: 0.3s;
}

.toggle-slider:before {
content: '';
position: absolute;
width: 16px;
height: 16px;
border-radius: 50%;
background: #a4b0c5;
top: 2px;
left: 2px;
transition: 0.3s;
}

.toggle-input:checked + .toggle-slider {
background: #2d5a27;
}

.toggle-input:checked + .toggle-slider:before {
transform: translateX(16px);
background: #8fcea6;
}

.search-section {
flex: 0 0 300px;
}

.search-input {
width: 100%;
padding: 8px 12px;
border: 1px solid #ffffff;
border-radius: 4px;
background-color: #283142;
color: #ffffff;
font-size: 14px;
transition: all 0.2s ease;
}

.search-input::placeholder {
color: #8b92a5;
}

.search-input:focus {
outline: none;
border-color: #4a90e2;
box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* Modern Compact Banking Style Table */
.table-responsive {
background: #ffffff;
border-radius: 16px;
box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
padding: 0.5rem;
margin: 1rem 0;
border: 1px solid rgb(255, 255, 255);
overflow-x: auto;
-webkit-overflow-scrolling: touch;
margin-bottom: 1rem;
}

.clockify-table {
width: 100%;
border-collapse: separate;
border-spacing: 0;
background-color: white;
font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Compact Header Styling */
.clockify-table th {
background-color: #f8fafc;
color: #64748b !important;
padding: 12px 16px;
text-align: left;
font-weight: 600;
font-size: 11px;
text-transform: uppercase;
letter-spacing: 0.03em;
border-bottom: 1px solid #e2e8f0;
white-space: nowrap;
}

/* Compact Row Styling */
.clockify-table td {
  padding: 5px 15px;
font-size: 13px;
color: #1e293b !important;
border-bottom: 1px solid #f1f5f9;
vertical-align: middle;
height: 40px;
white-space: nowrap;
}

.clockify-table tbody tr {
transition: all 0.15s ease;
height: 40px;
}

.clockify-table tbody tr:hover {
background-color: #fafafa;
backdrop-filter: blur(4px);
}

/* Compact Task Name */
.task-name {
color: #0f172a !important;
font-weight: 500;
font-size: 13px;
max-width: 200px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
cursor: pointer;
color: #2F467A;;
transition: color 0.2s;
}

.task-name:hover {
  color: #1976d2;
  text-decoration: underline;
}

/* Compact Project Badge */
.project-name {
background-color: #f8fafc;
color: #ffffff !important;
padding: 4px 8px;
border-radius: 4px;
font-size: 12px;
font-weight: bold;
display: inline-block;
max-width: 150px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
border: 1px solid #f1f5f9;
}

/* Compact User Info */
.user-info {
display: flex;
align-items: center;
gap: 8px;
max-width: 150px;
}

.user-name {
color: #334155 !important;
font-weight: 500;
font-size: 13px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

/* Compact Date Time */
.date-time-display {
display: flex;
flex-direction: column;
gap: 2px;
}

.date {
color: #334155 !important;
font-weight: 500;
font-size: 13px;
}

.time-range {
color: #64748b !important;
font-size: 12px;
}

/* Compact Duration */
.time-display.duration {
font-family: 'SF Mono', 'Roboto Mono', monospace;
color: #0f172a !important;
font-weight: 500;
font-size: 13px;
letter-spacing: -0.3px;
}

/* Serial Number Column */
.clockify-table th:first-child,
.clockify-table td:first-child {
background-color: #f8fafc;
font-weight: 500;
color: #64748b !important;
border-right: 1px solid #e2e8f0;
width: 60px;
min-width: 60px;
text-align: center;
padding: 8px !important;
}

.clockify-table td:first-child {
font-family: 'SF Mono', 'Roboto Mono', monospace;
font-size: 12px;
}

.clockify-table tbody tr:hover td:first-child {
background-color: #f1f5f9;
color: #0f172a !important;
}

/* Zebra Striping */
.clockify-table tbody tr:nth-child(even) {
background-color: #fafbfc;
}

/* Hover Effects */
.clockify-table tbody tr:hover td {
background-color: rgba(255, 255, 255, 0.95);
box-shadow: 0 0 0 1px #e2e8f0;
position: relative;
}

/* Column Borders */
.clockify-table td:not(:last-child),
.clockify-table th:not(:last-child) {
border-right: 1px solid #f1f5f9;
}

/* Custom Scrollbar */
.table-responsive::-webkit-scrollbar {
width: 4px;
height: 4px;
}

.table-responsive::-webkit-scrollbar-track {
background: #f1f5f9;
border-radius: 2px;
}

.table-responsive::-webkit-scrollbar-thumb {
background: #cbd5e1;
border-radius: 2px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
background: #94a3b8;
}

/* Status Indicators */
.status-indicator {
width: 6px;
height: 6px;
border-radius: 50%;
display: inline-block;
margin-right: 8px;
}

.status-active {
background-color: #22c55e;
}

.status-pending {
background-color: #f59e0b;
}

.status-completed {
background-color: #3b82f6;
}

/* Total Section */
.total-item {
background: linear-gradient(to right, #f8fafc, #ffffff);
padding: 8px 12px;
border-radius: 6px;
display: flex;
align-items: center;
gap: 8px;
height: 36px;
}

.total-item .label {
color: #64748b;
font-size: 12px;
font-weight: 500;
}

.total-item .value {
color: #0f172a;
font-size: 13px;
font-weight: 600;
font-family: 'SF Mono', 'Roboto Mono', monospace;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .filter-container {
      gap: 10px;
      padding: 10px;
  }
  
  .filter-group {
      flex: 1 1 calc(33.333% - 10px);
      min-width: 200px;
  }
}

@media (max-width: 992px) {
  .filter-group {
      flex: 1 1 calc(50% - 10px);
  }
  
  .clockify-table {
      overflow-x: auto;
      display: block;
  }
  
  .clockify-table table {
      min-width: 800px;
  }
}

@media (max-width: 768px) {
  .filter-container {
      flex-direction: column;
      align-items: stretch;
  }
  
  .filter-group {
      width: 100%;
      margin-bottom: 10px;
  }
  
  .btn-primary {
      width: 100%;
      margin-top: 10px;
  }
  
  .p-multiselect {
      width: 100%;
  }
  
  .calendar-input {
      width: 100%;
  }
}

@media (max-width: 576px) {
  .header-marquee {
      padding: 5px 0;
  }
  
  .clockify-table th,
  .clockify-table td {
      padding: 8px;
      font-size: 0.9rem;
  }
  
  .filter-container {
      padding: 8px;
  }
}

/* Improve form controls responsiveness */
.form-select,
.p-multiselect,
.p-calendar {
  max-width: 100%;
  width: 100%;
}

/* Custom MultiSelect Styles */
.multiselect-custom {
min-width: 250px;
background-color: #1c8ffcba;
border-radius: 4px;
z-index: 1;
}

.multiselect-custom .p-multiselect-label {
color: #ffffff;
padding: 8px 12px;
font-size: 14px;
}

.multiselect-custom .p-multiselect-trigger {
color: #8b92a5;
}

.multiselect-custom:not(.p-disabled):hover {
border-color: #4a90e2;
}

.multiselect-custom:not(.p-disabled).p-focus {
box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
border-color: #4a90e2;
}

.multiselect-custom .p-multiselect-panel {
background-color: #283142;
border: 1px solid #ffffff;
border-radius: 4px;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
min-width: 250px;
z-index: 1;
}

.multiselect-custom .p-multiselect-items-wrapper {
max-height: 300px !important;
}

.multiselect-custom .p-multiselect-items {
padding: 0;
}

.multiselect-custom .p-multiselect-item {
color: #ffffff;
padding: 8px 12px;
border-radius: 4px;
margin: 2px;
height: 40px;
display: flex;
align-items: center;
}

.multiselect-custom .p-virtualscroller {
height: 300px;
overflow-y: auto;
}

.multiselect-custom .p-virtualscroller-loader {
display: flex;
align-items: center;
justify-content: center;
color: #ffffff;
padding: 8px;
}

.multiselect-custom .p-virtualscroller::-webkit-scrollbar {
width: 6px;
}

.multiselect-custom .p-virtualscroller::-webkit-scrollbar-thumb {
background-color: rgba(255, 255, 255, 0.2);
border-radius: 3px;
}

.multiselect-custom .p-virtualscroller::-webkit-scrollbar-track {
background-color: rgba(0, 0, 0, 0.1);
}

/* User Dropdown Panel Styles */
.user-dropdown-panel {
background-color: #283142 !important;
border: 1px solid #ffffff !important;
border-radius: 4px !important;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
padding: 8px !important;
}

.user-dropdown-panel .p-multiselect-filter-container {
margin-bottom: 8px;
}

.user-dropdown-panel .p-multiselect-filter {
padding: 8px 12px;
background-color: #ffffff;
border: 1px solid #ffffff;
border-radius: 4px;
color: #000000;
}

.user-dropdown-panel .p-multiselect-filter:focus {
border-color: #4a90e2;
box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.user-dropdown-panel .p-multiselect-items-wrapper {
max-height: 300px !important;
}

.user-dropdown-panel .p-multiselect-items {
padding: 0 !important;
}

.user-dropdown-panel .p-multiselect-item {
padding: 8px 12px !important;
color: #ffffff !important;
border-radius: 4px !important;
margin: 2px 0 !important;
transition: background-color 0.2s !important;
}

.user-dropdown-panel .p-multiselect-item:hover {
background-color: rgba(74, 144, 226, 0.1) !important;
}

.user-dropdown-panel .p-multiselect-item.p-highlight {
background-color: rgba(74, 144, 226, 0.2) !important;
color: #4a90e2 !important;
}

.user-dropdown-panel .p-multiselect-empty-message {
padding: 8px 12px;
color: #8b92a5;
}

.loading-text {
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
}

.p-multiselect-panel .p-virtualscroller-loading {
  justify-content: center;
  align-items: center;
}

/* Modern Dialog Styles */
.modern-dialog {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.modern-dialog .p-dialog-content {
  padding: 0;
}

.task-details-modern {
  background: white;
}

.task-details-modern .dialog-header {
  background: #1c8ffc;
  color: white;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-details-modern .dialog-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.task-details-modern .close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.task-details-modern .close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.task-details-modern .dialog-content {
  padding: 20px;
}

.task-details-modern .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.task-details-modern .avatar {
  width: 36px;
  height: 36px;
  background: #1c8ffc;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 12px;
}

.task-details-modern .name {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.task-details-modern .project-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.9rem;
  margin-bottom: 16px;
}

.task-details-modern .description-box {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  color: #333;
  line-height: 1.5;
  font-size: 0.95rem;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Override PrimeReact Dialog mask */
.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}