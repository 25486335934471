/* General navbar styling */
.navbar {
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.contact-us-landing .section_tab_title {
 padding: 4rem;
}
.christmas-cap-animation {
  transition: transform 0.3s ease;
}
/* Splash Screen Styles */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeOut 0.5s ease-out forwards;
  animation-delay: 3s;
}

.splash-content {
  width: 80%;
  max-width: 500px;
}

.tree-animation {
  width: 100%;
  height: 100%;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.christmas-cap-animation:hover {
  transform: scale(1.1);
}
.contact-us-landing .icon_box_landing h4 {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

.contact-us-landing .icon_box_landing p {
  margin: 5px 0;
  color: #666;
}

.contact-form .form-control {
  background-color: #fff;
  color: #333;
  font-size: 1rem;
}

.contact-form .btn {
  transition: background-color 0.3s ease;
}

.contact-form .btn:hover {
  background-color: #ff6b6b;
}

.container-fluid.bg-white {
  margin: 0px;
  padding: 0;
      overflow-x: hidden;
}
.login-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(46, 232, 253, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease;
}

.login-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.login-button:hover {
  box-shadow: 0 4px 20px rgba(0, 123, 255, 0.5);
  color: white;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.logoLanding {
  flex: 1;
}

/* .Landing-Page-class {
  max-height: 50px;
} */
header.navbar {
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 18%), 0px 3px 7px -3px rgb(13 17 20 / 79%), 0px 6px 12px -2px rgb(0 77 161 / 57%);
height: 5rem;
}
.navbar-container {
  flex: 2 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.desktop-nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-items {
  display: flex;
  gap: 30px;
  flex: 1;
  justify-content: center;
}

.desktop-nav a {
  text-decoration: none;
  color: #333;
  padding: 10px 15px;
  border-radius: 5px;
}

.desktop-nav a.active, .desktop-nav a:hover {
 
  color: #1519f7;
}

.login-button {
  background-color: #000000;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Mobile nav styling */
.mobile-nav-icon {
  display: none;
}

.mobile-nav {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  width: 100%;
  padding: 10px;
}

.mobile-nav a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.mobile-nav a:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.mobile-nav.open {
  display: block;
}
.mobile-nav.close{
  display: none;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav-icon {
    display: block;
  }
}


.row1 {
  display: flex;
  flex-direction: row-reverse;
}
.col-md-6.text-center.d-flex.flex-column.justify-content-center.align-items-center.hero-content {
  color: #272b30;
  text-align: center;
  margin-top: 3rem;
  font-size: 20px;
  
  letter-spacing: -1.2px;
  max-width: 100%;
}

/* .nav-wrapper {
  background: #fff;
  box-shadow: 0px 0px 20px 6px rgb(0 122 255 / 10%), 0px 3px 7px -3px rgb(0 122 255 / 10%), 0px 6px 12px -2px rgb(0 122 255 / 10%);
  height: 6rem;
  max-width: 138rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
} */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
}
/* .landingHero{
  padding: 50px;

} */
.main-hero{
  padding:0px 100px 0px 100px ;
}
.main-hero div{
  padding: 0px 20px 0px 0px;
  background-color: #fcfcfc;
}
.nav-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoLanding{
  flex: 0 0 auto;
  /* width: 25%; */
}
.logoLanding img {
  max-width: 150px;
  height: auto;
}

.desktop-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
  flex: 0 0 auto;
  width: auto;
  font-size: 16px;
  font-weight: bold;
}

.desktop-nav a {
  margin-right: 15px;
  text-decoration: none;
  color: #333;
}

.mobile-nav-icon {
  display: none; /* Initially hide for larger screens */
  cursor: pointer;
}

.mobile-nav {
  display: none; /* Initially hide for larger screens */
  flex-direction: column;
  position: absolute;
  font-weight: 600;
  top: 90px; /* Adjust the distance from the top as needed */
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.mobile-nav a {
  margin: 10px;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.mobile-nav a.active,
.desktop-nav a.active {
 /* Change text color of the active link */
}


.mobile-nav a.active::before,
.desktop-nav a.active::before {
  content: "";
  position: absolute;
  bottom: 0px; /* Adjust as needed */
  left: 0;
  width: 0;
  height: 3px; /* Adjust as needed */
  background-color: blue; /* Change the underline color */
  transition: width 0.3s ease;
}

.mobile-nav a.active:hover::before,
.desktop-nav a.active:hover::before {
  width: 100%;
}
.section_tab_title{
  font-size: 28px;
  font-weight: bold;
  /* text-transform: uppercase; */
  position: relative;
  color: #434175;
}
@media (max-width: 600px) {
  .landingHero{
    padding: 30px;
    padding-bottom: 5px;
    background-color: white;
  }
  .service_section row{
    padding: 0px;
  }
  .desktop-nav {
    display: none;  
  }
  .main-hero{
    margin-top: 0px;
    padding: 0px;
  }
  .main-hero div{
    padding: 0px 20px 0px 20px;
    background-color: #fcfcfc;
  }

  #custom_nav_classs{
    margin-top: -10px;
  }
  .mobile-nav-icon {
    display: flex;
    justify-content: flex-end; /* Align to the right */
    margin-right: 10px; 
  }

  .mobile-nav.open {
    display: flex;
    animation: fadeIn 0.8s ease;
    align-items: center;
  }
  
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hero-content {
  /* background-color: #f8f9fa;
  padding: 30px; */
  /* border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); */
  margin-bottom: 20px;
  transform: scale(1);
  opacity: 0;
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-content:hover {
  transform: scale(1.02);
}

.hero-button {
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 7px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  color: white;
}

.hero-button:hover {
  background-color: #4b4efa;
  color: #fff;
  transform: scale(1.1);
}

.feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}
.feature-box i{
  background: #ecf3ff;
  margin-right: 5px;
  padding: 5px;
  border-radius: 3px;
  transition: 0.3s;
}
.feature-box i:hover{
  background: #4b4efa;
  color: white;

}
.contact_us_landing{
  background-color:#f5f8fd73;
  padding: 5px;
}


/* .btn-buy{
  padding: 10px;
  background-color: #4b4efa;
  text-decoration: none;
  color: white;
  border-radius: 7px;
}
.btn-buy:hover{
  background-color: #3e40ce; 
  color: white;
}
.pricing .box {
  animation: fadeInUp 1s ease-in-out;
} */
@keyframes hoverAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
/* .pricing .box:hover {
  animation: hoverAnimation 0.2s ease-in;
} */



/* About Section Styles */

.about-section {
  padding: 80px 0;
  background-color: #fff;
}

.about-header {
  max-width: 800px;
  margin-bottom: 60px;
}

.section-tag {
  background-color: rgba(26, 206, 144, 0.1);
  color: #1ACE90;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 24px;
}

.about-title {
  font-size: 48px;
  font-weight: 700;
  color: #2D3748;
  margin-bottom: 24px;
  line-height: 1.2;
}

.about-description {
  font-size: 18px;
  color: #718096;
  line-height: 1.6;
  margin-bottom: 32px;
  max-width: 600px;
}

.explore-btn {
  background-color: #4299E1;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.explore-btn:hover {
  background-color: #3182CE;
  transform: translateY(-2px);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 60px;
}

.feature-card {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 48px;
  height: 48px;
  background-color: rgba(26, 206, 144, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.feature-icon i {
  font-size: 24px;
  color: #1ACE90;
}

.feature-card h3 {
  font-size: 20px;
  font-weight: 600;
  color: #2D3748;
  margin-bottom: 12px;
}

.feature-card p {
  font-size: 16px;
  color: #718096;
  line-height: 1.6;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-title {
    font-size: 36px;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .about-header {
    text-align: center;
  }
  
  .about-description {
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
    text-align: center;
    /* margin: 10px auto 50px auto; */
    z-index: 1;
    /* margin-top: 100px; */
}
/* Container and Title Styling */
.pricing-container {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 20px;
  width: 95%;
  margin: auto;
  text-align: center;
}

.title {
  color: #FFFFFF;
  font-family: 'system-ui', sans-serif;
  font-size: 36px;
  margin-bottom: 40px;
}

/* Pricing Container */
.pricing {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35rem;
}

/* Pricing Box Styling */
.pricing-box {
  background: linear-gradient(180deg, #1D2671, #C33764);
  padding: 30px;
  border-radius: 15px;
  width: 20rem;
  text-align: center;
  color: #FFFFFF;
  font-family: 'system-ui', sans-serif;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.featured {
  background: linear-gradient(180deg, #FF4B2B, #FF416C);
}

.plan-name {
  font-size: 24px;
  margin-bottom: 20px;
}

.price {
  font-size: 28px;
  margin-bottom: 20px;
}

/* Features List */
.pricing-box ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: left;
  font-size: 14px;
}

.pricing-box ul li {
  margin-bottom: 10px;
}

.pricing-box ul .na {
  opacity: 0.5;
}

/* Button Styling */
.btn-buy {
  background-color: #FFFFFF;
  color: #C33764;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}

.featured .btn-buy {
  color: #FF416C;
}

.pricing-box:hover .btn-buy {
  background-color: #FF416C;
  color: #FFFFFF;
}

/* Featured Offer Tag */
.small-colored {
  background-color: #FF416C;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .pricing {
      flex-direction: column; /* Stack the boxes vertically */
      align-items: center;
  }

  .pricing-box {
      width: 80%; /* Increase width for better visibility on small screens */
      margin-bottom: 20px;
  }

  .title {
      font-size: 28px; /* Adjust title size for smaller screens */
  }
}

@media (max-width: 480px) {
  .pricing-box {
      width: 90%; /* Further increase width on very small screens */
  }

  .plan-name {
      font-size: 20px; /* Adjust text size for readability */
  }

  .price {
      font-size: 24px;
  }
}
/* Hover Effects */
.pricing-box:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.pricing-box.featured:hover {
  transform: scale(1.08);
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

ul li {
    margin: 10px 0;
    color: #ffffff;
}

.na {
    color: rgb(255, 255, 255);
    text-decoration: line-through;
}

.btn-buy {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    background: #0ebeff;
    color: #fff;
    margin: 20px auto;
    padding: 10px 20px;
    text-decoration: none;
    border: 0;
    transition-duration: 0.5s;
}

.btn-buy:hover {
    background: #47cf73;
}

body::before,
body::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
}

/* body::before {
    border-top: 30vw solid #4760cf;
    border-right: 30vw solid transparent;
    top: 0;
    left: 0;
}

body::after {
    border-bottom: 30vw solid black;
    border-left: 30vw solid transparent;
    right: 0;
    margin-top: -50px;
} */

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
.pricing {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2d3748;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.description-title {
  color: #47b2e4;
  font-weight: 600;
  font-size: 32px;
}

.pricing-item {
  padding: 60px 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #eef0f2;
}

.pricing-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
}

.pricing-item h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #2d3748;
}

.pricing-item .description {
  font-size: 16px;
  color: #718096;
  margin-bottom: 30px;
  line-height: 1.6;
}

.pricing-item h4 {
  font-size: 48px;
  color: #2d3748;
  font-weight: 700;
  margin-bottom: 25px;
}

.pricing-item h4 sup {
  font-size: 28px;
  position: relative;
  top: -15px;
}

.pricing-item h4 span {
  color: #718096;
  font-size: 18px;
  font-weight: 400;
}

.pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: #718096;
  text-align: left;
  line-height: 20px;
}

.pricing-item ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pricing-item ul i {
  color: #059652;
  font-size: 20px;
  padding-right: 15px;
}

.pricing-item ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing-item ul .na i {
  color: #ccc;
}

.pricing-item .cta-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #47b2e4;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 20px;
}

.pricing-item .cta-btn:hover {
  background: #209dd8;
  transform: translateY(-2px);
}

.pricing-item.featured {
  border: 3px solid #47b2e4;
  transform: scale(1.05);
}

.pricing-item.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

.pricing-item.featured .popular {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 7px 20px;
  background: #47b2e4;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .pricing-item {
    margin-bottom: 30px;
  }
  
  .pricing-item.featured {
    transform: scale(1);
  }
  
  .pricing-item.featured:hover {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.about-content .lead {
  font-size: 1.25rem;
  color: #1ace90;
  margin-bottom: 20px;
  font-weight: 500;
}

.about-content p {
  font-size: 1rem;
  color: #718096;
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-features {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.feature-item i {
  color: #1ace90;
  font-size: 1.25rem;
  margin-right: 15px;
}

.feature-item span {
  color: #4a5568;
  font-size: 1.1rem;
  font-weight: 500;
}

.learn-more-btn {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

@media (max-width: 992px) {
  .about-section {
    padding: 60px 0;
  }

  .about-content {
    margin-top: 40px;
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .feature-item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 40px 0;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content .lead {
    font-size: 1.1rem;
  }
}

/* Features Section */
.features-wrapper {
  padding: 80px 0;
  background-color: #f8f9fa;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.features-header {
  text-align: center;
  margin-bottom: 50px;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-header p {
  font-size: 1.1rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-box h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 600;
}

.feature-box p {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 20px;
  line-height: 1.6;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}

.feature-box ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4a5568;
  font-size: 0.95rem;
}

.feature-box ul li i {
  color: #1ace90;
  margin-right: 10px;
  font-size: 1.1rem;
}

.features-cta {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.features-cta h3 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 15px;
  font-weight: 700;
}

.features-cta p {
  font-size: 1.1rem;
  color: #718096;
  margin-bottom: 25px;
}

.cta-button {
  background: linear-gradient(135deg, #1ace90 0%, #0fb77b 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(26, 206, 144, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-wrapper {
    padding: 60px 0;
    margin-top: 30px;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-box {
    padding: 25px;
  }

  .features-cta {
    margin-top: 40px;
    padding: 30px 20px;
  }

  .features-cta h3 {
    font-size: 1.75rem;
  }
}

/* Pricing Section */
