.leave_info {
  font-size: 13px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .col-lg-3 {
      flex: 0 0 auto;
      width: 20% !important;
      height: 20% !important;
  }
}

.status {
  font-size: 16px;
  font-weight: 700;
}
.row.mb-3 {
  margin-top: 5% !important;
}
@media (min-width: 1200px) {
  .col-xl-3 {
      flex: 0 0 auto;
      width: 20% !important;
      height: 20% !important;
  }
}

.leave-reason {
  color: #495057;
}

.leave-reason p {
  margin: 0;
  padding: 0;
}

.leave-reason span {
  color: inherit !important;
}

.timeline-content {
  transition: background-color 0.2s ease;
}

.timeline-content:hover {
  background-color: #f1f3f5 !important;
}