.holidays-container {
  margin-top: 35px;
  padding: 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.holidays-title {
  color: #1a73e8;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #e8eaed;
  padding-bottom: 0.75rem;
}

.table-container {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.react-bootstrap-table {
  background: #fff;
}

.table {
  margin-bottom: 0;
}

.table thead th {
  background-color: #21117D;
  color: white !important;
  font-family: Roboto-slab;
  font-weight: 400;
  font-size: 17px;
  padding: 1rem;
  border-bottom: 2px solid #e8eaed;
  white-space: nowrap;
}

.table tbody td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: 1px solid #e8eaed;
}

.holiday-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 500;
  display: inline-block;
}

.holiday-upcoming {
 
  color: #1e8e3e;
}

.holiday-past {

  color: #d93025;
}

.holiday-current {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.pagination {
  margin-top: 1rem;
}

.page-item.active .page-link {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

.page-link {
  color: #1a73e8;
  padding: 0.5rem 1rem;
}

.page-link:hover {
  background-color: #e8f0fe;
}

.holiday-legend {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.legend-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.legend-upcoming {
  background-color: #1e8e3e;
}

.legend-past {
  background-color: #d93025;
}

.legend-current {
  background-color: #1a73e8;
}

.scroll-table-container {
  max-height: 550px;
  width: 100%;
  overflow-y: auto !important;
}

.scroll-table {
  width: 100%;
  overflow-y: auto !important;
}

.notification-section {
  display: flex;
  align-items: center;
}

.passed-holidays-btn,
.upcoming-holidays-btn {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  margin-right: 5px;
}

.passed-holidays-btn {
  background-color:#00ccff ;
}
.PassedLeave{
  background-color:#00ccff ;
}

.upcoming-holidays-btn {
  background-color: #f1184e;
}
.UpcommingLeave{
  background-color: #f1184e;
}

.notification-label {
  font-weight: bold;
}

.notification-text {
  font-size: 14px;
  color: #2c46d8;
}

@media screen and (max-width: 768px) {
  .holidays-container {
    padding: 1rem;
  }
  
  .table-responsive {
    margin: 0 -1rem;
  }
  
  .holiday-legend {
    justify-content: center;
  }
  
  .scroll-table-container {
    max-height: 400px;
  }
  
  .table {
    min-width: 600px; /* Ensure table doesn't get too narrow */
  }
  
  .table thead th {
    padding: 8px; /* Smaller padding on mobile */
  }
  
  .table td {
    padding: 8px; /* Smaller padding on mobile */
  }
  
  .notification-section {
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    gap: 10px; /* Space between wrapped items */
  }
}

@media screen and (max-width: 480px) {
  .scroll-table-container {
    max-height: 350px;
  }
  
  .table thead th {
    font-size: 14px; /* Smaller font on very small screens */
  }
  
  .notification-text {
    font-size: 12px;
  }
}
