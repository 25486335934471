/* Base Container */
.dashboard-container {
  padding: 1.5rem;
  background-color: #f8f9fa;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Header Styles */
.dashboard-header {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  margin: 2rem 0 1.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.dashboard-header h2 {
  color: #2c3e50;
  margin: 0;
  font-size: clamp(1.25rem, 4vw, 1.75rem);
  font-weight: 600;
}

/* Dashboard Content */
.dashboard-content {
  display: grid;
  gap: 1.5rem;
  width: 100%;
}

/* Filter Panel */
.filter-panel {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  width: 100%;
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
}

.filter-header i {
  color: #6366F1;
  font-size: 1.25rem;
}

.filter-header h3 {
  margin: 0;
  font-size: clamp(1.1rem, 3vw, 1.25rem);
  color: #2c3e50;
}

.filter-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.date-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  width: 100%;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.filter-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6c757d;
  font-size: 0.875rem;
  white-space: nowrap;
}

/* Calendar Customization */
.custom-calendar {
  width: 100% !important;
}

.custom-calendar input {
  width: 100% !important;
  padding: 0.75rem 1rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  color: #2c3e50;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

/* Search Button */
.search-button {
  width: 100%;
  max-width: 300px;
  margin: 0.5rem auto;
  padding: 0.75rem 1.5rem !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
}

/* Table Section */
.data-table-section {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  width: 100%;
  overflow-x: auto;
}

.table-container {
  min-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.modern-data-table {
  min-width: 800px; /* Minimum width to prevent squishing */
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.modern-data-table th {
  /* background: #f8f9fa !important; */
  color: #2c3e50 !important;
  font-weight: 600 !important;
  padding: 1rem !important;
  border-bottom: 2px solid #e9ecef !important;
  white-space: nowrap;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
}

.modern-data-table td {
  padding: 1rem !important;
  border-bottom: 1px solid #e9ecef !important;
  color: #2c3e50 !important;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
}

.table-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

/* Pagination */
.pagination-size-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-size-selector span {
  color: #6c757d;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
}

.size-button {
  padding: 0.5rem 0.75rem;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  min-width: 40px;
  text-align: center;
}

/* Loading State */
.loading-state {
  padding: 2rem;
  text-align: center;
}

.loading-state span {
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  margin-top: 1rem;
  display: block;
}

/* No Data Message */
.no-data-message {
  padding: 2rem 1rem;
  text-align: center;
}

.no-data-message h3 {
  font-size: clamp(1.1rem, 3vw, 1.25rem);
}

.no-data-message p {
  font-size: clamp(0.8rem, 2vw, 0.875rem);
}

/* Responsive Breakpoints */
@media (max-width: 1024px) {
  .dashboard-container {
    padding: 1rem;
  }

  .date-filters {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 0.75rem;
  }

  .dashboard-header,
  .filter-panel,
  .data-table-section {
    padding: 1rem;
    border-radius: 8px;
  }

  .date-filters {
    grid-template-columns: 1fr;
  }

  .search-button {
    width: 100%;
    max-width: none;
  }

  .table-container {
    margin: 0 -1rem;
    padding: 0 1rem;
    width: calc(100% + 2rem);
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    padding: 0.5rem;
  }

  .filter-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .pagination-size-selector {
    flex-direction: column;
    align-items: stretch;
  }

  .size-button {
    width: 100%;
    margin: 0.25rem 0;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .search-button,
  .size-button,
  .modern-data-table tbody tr {
    transition: none !important;
  }

  .custom-calendar input,
  .search-button {
    font-size: 16px !important; /* Prevents zoom on iOS */
  }
}

/* Print Styles */
@media print {
  .dashboard-container {
    padding: 0;
    background: white;
  }

  .filter-panel,
  .search-button,
  .pagination-size-selector {
    display: none;
  }

  .modern-data-table {
    min-width: 100%;
  }
}
