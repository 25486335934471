General Styling
body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9fafb;
  }
  .p-6 {
    padding: 3rem !important;
}
  .min-h-screen {
    min-height: 100vh;
  }
  
  .bg-gray-100 {
    background-color: #f9fafb;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .text-gray-800 {
    color: #1f2937;
  }
  
  .text-gray-700 {
    color: #374151;
  }
  
  .text-red-500 {
    color: #ef4444;
  }
  
  .shadow-xl {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
  }
  
  .rounded-xl {
    border-radius: 12px;
  }
  





/* Focused States */
input:focus,
select:focus,
textarea:focus {
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
}

/* Error Messages */


/* Success Messages (Optional) */
.success-message {
  color: #10b981;
  font-size: 12px;
  margin-top: 4px;
}

/* Button Disabled State */
button:disabled {
  background-color: #d1d5db;
  color: #9ca3af;
  cursor: not-allowed;
}

  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
  }
  
  
  .focus\:ring-red-500 {
    box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.3);
  }
  
  /* Button Styling */
  button {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    background-color: #2563eb;
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  button:hover {
    background-color: #1d4ed8;
    transform: translateY(-2px);
  }
  
  button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.4);
  }
  
  /* Layout and Spacing */
  .space-y-6 > *:not(:last-child) {
    margin-bottom: 24px;
  }
  
  .grid {
    display: grid;
    gap: 16px;
  }
  
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  @media (max-width: 768px) {
    .grid-cols-2 {
      grid-template-columns: 1fr;
    }
  }
  
  .label {
    font-weight: 600;
    color: #374151;
    margin-bottom: 8px;
  }
  
  /* Typography */
  h1 {
    font-size: 28px;
    font-weight: 700;
    color: #1f2937;
  }
  

  /* Optional Transition Effect */
  .bg-fade {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  