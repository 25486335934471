.user-analysis-container {
  padding: 8px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  flex-direction: column;
}

.filters-container {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.summary-cards {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summary-card {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

.summary-card:hover {
  transform: translateY(-2px);
}

.card-title {
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.card-value {
  font-size: 1.8rem;
  font-weight: 600;
  color: #03045e;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin-top: 8px;
  margin-bottom: 2rem;
}

.chart-card {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.data-table-container {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #f8f9fa;
  color: #495057;
  font-weight: 600;
  padding: 1rem;
}

.p-dropdown {
  width: 100%;
}

.p-calendar {
  width: 100%;
}

.export-button {
  background-color: #03045e !important;
  color: white !important;
  text-transform: none !important;
  padding: 0.5rem 1.5rem !important;
}

.export-button:hover {
  background-color: #020336 !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.error-message {
  color: #dc3545;
  padding: 1rem;
  margin: 1rem 0;
  background: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

.table-chart-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
}

.table-section {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pie-chart-section {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.pie-chart-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.loader-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader-line-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rotate 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-line {
  position: absolute;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #0077b6;
  top: 50%;
  transform: translateY(-50%);
  animation: scale 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-line-wrap:nth-child(1) {
  animation-delay: -0.1s;
}

.loader-line-wrap:nth-child(2) {
  animation-delay: -0.2s;
}

.loader-line-wrap:nth-child(3) {
  animation-delay: -0.3s;
}

.loader-line-wrap:nth-child(4) {
  animation-delay: -0.4s;
}

.loader-line-wrap:nth-child(5) {
  animation-delay: -0.5s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0%, 100% {
    transform: translateY(-50%) scale(1);
  }
  50% {
    transform: translateY(-50%) scale(0.5);
  }
}

@media (max-width: 768px) {
  .summary-cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .charts-container {
    grid-template-columns: 1fr;
  }
  
  .user-analysis-container {
    padding: 1.5rem;
  }
  
  .table-chart-container {
    grid-template-columns: 1fr;
  }
}